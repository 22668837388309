<template>
  <div class="address_input_google">
    <el-row>
      <el-col :span="4">
        <div class="w-100 p-1 text-center title">{{ $t("Name") }}</div>
      </el-col>
      <el-col :span="8">
        <div class="w-100 p-1 text-center title">{{ $t("Address") }}</div>
      </el-col>
      <el-col :span="6">
        <div class="w-100 p-1 text-center title">{{ $t("Remarks") }}</div>
      </el-col>
      <el-col :span="2">
        <div class="w-100 p-1 text-center title">Billing Add.</div>
      </el-col>
      <el-col :span="2">
        <div class="w-100 p-1 text-center title">Shipping Add.</div>
      </el-col>
      <el-col :span="2">
        <div class="w-100 p-1 text-center title2" @click="start_new_address()">
          <el-tooltip
            effect="light"
            content="Add New Address"
            placement="top"
            style="margin: auto"
            :openDelay="300"
          >
            <i class="ni ni-fat-add icon-add"></i>
          </el-tooltip>
        </div>
      </el-col>
    </el-row>
    <el-row v-for="addr in cur_address_list" :key="addr.IDn">
      <el-col :span="4">
        <div class="w-100 p-1 txtbody">
          {{ addr.Name ? addr.Name : "-" }}
        </div>
      </el-col>
      <el-col :span="8">
        <div class="w-100 p-1 txtbody">
          {{ address_combination(addr) }}
        </div>
      </el-col>
      <el-col :span="6">
        <div class="w-100 p-1 txtbody">
          {{ addr.Remarks ? addr.Remarks : "-" }}
        </div>
      </el-col>
      <el-col :span="2">
        <div class="w-100 p-1 text-center txtbody">
          <el-checkbox
            :checked="addr.BillingFlag == 1"
            @change="set_billing_address(addr)"
          ></el-checkbox>
        </div>
      </el-col>
      <el-col :span="2">
        <div class="w-100 p-1 text-center txtbody">
          <el-checkbox
            :checked="addr.ShippingFlag == 1"
            @change="set_shipping_address(addr)"
          ></el-checkbox>
        </div>
      </el-col>
      <el-col :span="2">
        <div class="w-100 p-1 text-center" @click="delete_address(addr)">
          <i class="ni ni-fat-remove icon-remove"></i>
        </div>
      </el-col>
    </el-row>

    <b-modal
      ref="address-modal"
      size="lg"
      title="New Address"
      id="address_modal"
    >
      <section>
        <el-row :gutter="10" style="margin-left: 10px">
          <el-col :span="24">
            <b-input
              type="text"
              v-model="search"
              @input="onChange"
              placeholder="Please input your address"
              autocomplete="off"
            />
            <ul v-show="isOpen" class="autocomplete2-results">
              <li
                v-for="(result, i) in search_results"
                :key="i"
                @click="setResult(result)"
                class="autocomplete2-result"
              >
                {{ result }}
              </li>
            </ul>
          </el-col>
        </el-row>
        <el-row :gutter="10" style="margin-left: 10px">
          <el-col :span="24">
            <base-input :label="$t('Address Name')">
              <b-input
                :placeholder="$t('Address Name')"
                class="form-control"
                v-model="cur_address.Name"
              />
            </base-input>
          </el-col>
          <el-col :span="24">
            <base-input :label="$t('Address Line') + ' 1'">
              <b-input
                :placeholder="$t('Address Line') + ' 1'"
                class="form-control"
                v-model="cur_address.Street1"
              />
            </base-input>
          </el-col>
          <el-col :span="24">
            <base-input :label="$t('Address Line') + ' 2'">
              <b-input
                :placeholder="$t('Address Line') + ' 2'"
                class="form-control"
                v-model="cur_address.Street2"
              />
            </base-input>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <base-input :label="$t('Country')">
              <select
                class="form-control"
                v-model="cur_address.CountryIDn"
                @change="changed_country"
              >
                <option
                  v-for="(country, countryIDn) in countrylist"
                  :key="countryIDn"
                  :value="countryIDn"
                >
                  {{ country.Country
                  }}<!-- -{{ countryIDn }} -->
                </option>
              </select>
            </base-input>
          </el-col>
          <el-col :span="6">
            <base-input :label="$t('Province')">
              <select
                class="form-control"
                v-model="cur_address.ProvinceIDn"
                @change="changed_province"
              >
                <option
                  v-for="(province, idx) in cur_provincelist"
                  :key="idx"
                  :value="province.IDn"
                >
                  {{ province.Province
                  }}<!-- -{{ ProvinceIDn }} -->
                </option>
              </select>
            </base-input>
          </el-col>
          <el-col :span="6">
            <base-input :label="$t('City')">
              <InputAutocomplete
                :key="cur_address.AddressCity"
                :items="citylist"
                :initval="cur_address.AddressCity"
                @input="setinput_city"
              ></InputAutocomplete>
            </base-input>
          </el-col>
          <el-col :span="6">
            <base-input :label="$t('Postal Code')">
              <b-input
                :placeholder="$t('Postal Code')"
                class="form-control"
                v-model="cur_address.ZIP"
              />
            </base-input>
          </el-col>
        </el-row>
      </section>
      <template #modal-footer>
        <el-button type="primary" @click="cancel_edit_address">
          {{ $t("Cancel") }}
        </el-button>
        <el-button type="primary" @click="submit_address">
          {{ $t("Submit") }}
        </el-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Button,
  Pagination,
  Checkbox,
  Collapse,
  CollapseItem,
  Row,
  Col,
} from "element-ui";
import InputAutocomplete from "./InputAutocomplete";

const axios = require("axios");
export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Row.name]: Row,
    [Col.name]: Col,
    [Pagination.name]: Pagination,
    InputAutocomplete,
  },
  name: "AddressInput2",
  props: {
    address_type: {
      type: String,
      required: true,
      default: "customer",
    },
    token: {
      type: String,
      required: true,
      default: "",
    },
    user_id: {
      type: String,
      required: true,
      default: "",
    },
    ReferenceIDn: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      search: "",
      search_results: [],
      isOpen: false,

      cur_address_list: [],
      cur_address: {},
      new_address: {
        IDn: 0,
        Type: "",
        ReferenceIDn: 0,
        BillingFlag: 0,
        ShippingFlag: 0,
        Status: 1,
        Name: "",
        Street1: "",
        Street2: "",
        AddressCity: "",
        ProvinceIDn: 2,
        AddressProvince: "British Columbia",
        CountryIDn: 1,
        AddressCountry: "Canada",
        ZIP: "",
        Remarks: "",
      },

      cur_provincelist: [],
      countrylist: [],
      provincelist: [],
      citylist: [],
    };
  },
  methods: {
    onChange() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("w", this.search);
      bodyFormData.append("cIDn", this.cur_address.CountryIDn);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/MultiAdd/search",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.search_results = rt.data.addresslist;
              that.isOpen = true;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            alert(response.statusText);
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    setResult(result) {
      // Result: 110 Charles Street East, Toronto, ON, Canada
      this.search = result;
      var addrs = result.split(",");
      if (addrs[0] != undefined) {
        this.cur_address.Street1 = addrs[0];
        if (addrs[1] != undefined) {
          this.cur_address.AddressCity = addrs[1].trim();
        }
        var CIDn = 1; // Default to Canada
        if (addrs[3] != undefined) {
          var country = addrs[3].trim();
          for (var idx in this.countrylist) {
            if (this.countrylist[idx].Country == country) {
              CIDn = this.countrylist[idx].IDn;
              this.cur_address.AddressCountry = this.countrylist[idx].Country;
              break;
            }
          }
        }
        this.cur_address.CountryIDn = CIDn;
        var PIDn = 2; // Default to British Columbia
        if (addrs[2] != undefined) {
          var province = addrs[2].trim();
          for (var idx in this.provincelist) {
            if (this.provincelist[idx].ProvinceID == province) {
              PIDn = this.provincelist[idx].IDn;
              this.cur_address.AddressProvince = province;
              break;
            }
          }
        }
        this.cur_address.ProvinceIDn = PIDn;
      } else {
        this.cur_address.Street1 = result;
      }
      this.isOpen = false;
    },
    address_combination(address) {
      let rt = "";
      rt += address.Street2
        ? address.Street1 + " " + address.Street2
        : address.Street1;
      rt += address.AddressCity ? (rt ? ", " : "") + address.AddressCity : "";
      rt += address.AddressProvince
        ? (rt ? ", " : "") + address.AddressProvince
        : "";
      rt += address.AddressCountry
        ? (rt ? ", " : "") + address.AddressCountry
        : "";
      rt += address.ZIP ? (rt ? ", " : "") + address.ZIP : "";
      return rt;
    },
    cancel_edit_address() {
      this.$refs["address-modal"].hide();
    },
    start_new_address() {
      this.cur_address = JSON.parse(JSON.stringify(this.new_address));
      this.search = "";
      this.search_results = [];
      this.$refs["address-modal"].show();
    },
    async set_billing_address(address, event) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_opt);
      var that = this;
      bodyFormData.append("IDn", address.IDn);
      bodyFormData.append("ReferenceIDn", this.ReferenceIDn);
      bodyFormData.append("Type", this.address_type);
      axios({
        method: "post",
        url: "/Api/Web/MultiAdd/set_billing",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_address_list = [];
              that.$nextTick(() => {
                that.cur_address_list = rt.data;
              });
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            alert(response.statusText);
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    async set_shipping_address(address, event) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_opt);
      var that = this;
      bodyFormData.append("IDn", address.IDn);
      bodyFormData.append("ReferenceIDn", this.ReferenceIDn);
      bodyFormData.append("Type", this.address_type);
      axios({
        method: "post",
        url: "/Api/Web/MultiAdd/set_shipping",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_address_list = [];
              that.$nextTick(() => {
                that.cur_address_list = rt.data;
              });
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            alert(response.statusText);
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    async submit_address() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      for (var key in this.cur_address) {
        if (key == "ReferenceIDn") {
          bodyFormData.append("ReferenceIDn", this.ReferenceIDn);
        } else if (key == "Type") {
          bodyFormData.append("Type", this.address_type);
        } else {
          bodyFormData.append(key, this.cur_address[key]);
        }
      }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/MultiAdd/submit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              var newaddr = rt.data;
              that.cur_address_list.push(newaddr);
              that.$emit("address_added", newaddr.IDn);
              that.$refs["address-modal"].hide();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            alert(response.statusText);
          }
        })
        .catch(function (error) {
          //XXXXX that.$router.push("/login");
        });
    },
    delete_address(row) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("ReferenceIDn", row.ReferenceIDn);
      bodyFormData.append("IDn", row.IDn);
      bodyFormData.append("Type", row.Type);
      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/MultiAdd/delete",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            that.$emit("address_deleted", row.IDn);
            var rt = response.data;
            if (rt.status == 0) {
              for (var i = 0; i < that.cur_address_list.length; i++) {
                if (that.cur_address_list[i].IDn == row.IDn) {
                  that.cur_address_list.splice(i, 1);
                  break;
                }
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            alert(response.statusText);
          }
        })
        .catch(function (error) {
          // that.$router.push("/login");
        });
    },

    setinput_city(city) {
      this.cur_address.AddressCity = city;
    },
    changed_province() {
      if (this.cur_provincelist.length <= 0) {
        return;
      }

      if (
        !this.cur_address.ProvinceIDn ||
        this.cur_address.ProvinceIDn == undefined ||
        this.cur_address.ProvinceIDn <= 0
      ) {
        this.cur_address.ProvinceIDn = this.cur_provincelist[0].IDn;
      }
      this.cur_address.AddressProvince = "";
      for (var i = 0; i < this.cur_provincelist.length; i++) {
        if (this.cur_provincelist[i].IDn == this.cur_address.ProvinceIDn) {
          this.cur_address.AddressProvince = this.cur_provincelist[i].Province;
        }
      }
      if (this.cur_address.AddressProvince == "") {
        // Didn't find Province
        this.cur_address.ProvinceIDn = this.cur_provincelist[0].IDn;
      }
    },
    changed_country() {
      if (
        !this.cur_address.CountryIDn ||
        this.cur_address.CountryIDn == undefined ||
        this.cur_address.CountryIDn <= 0
      ) {
        this.cur_address.CountryIDn = 1; // Set default to Canada
      }

      for (var idx in this.countrylist) {
        if (this.countrylist[idx].IDn == this.cur_address.CountryIDn) {
          this.cur_address.AddressCountry = this.countrylist[idx].Country;
        }
      }
      this.cur_provincelist = [];
      for (var idx in this.provincelist) {
        if (this.provincelist[idx].CountryIDn == this.cur_address.CountryIDn) {
          this.cur_provincelist.push(this.provincelist[idx]);
        }
      }
      this.changed_province();
    },
    async initAddressInput() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      var that = this;
      bodyFormData.append("ReferenceIDn", this.ReferenceIDn);
      bodyFormData.append("address_type", this.address_type);

      axios({
        method: "post",
        url: "/Api/Web/MultiAdd/initAddressInput",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_address_list = rt.data.address_list;
              that.countrylist = rt.data.countries;
              that.provincelist = rt.data.provinces;
              that.citylist = rt.data.cities;
              that.cur_provincelist = [];
              that.changed_country();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            alert(response.statusText);
          }
        })
        .catch(function (error) {
          that.$router.push("/login");
        });
    },
    init_data() {
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      this.initAddressInput();
    },
  },
  mounted() {
    this.init_data();
  },
};
</script>
<style>
.address_input_google {
  position: relative;
}
.address_input_google .title {
  background-color: #dddddd;
  border-right: 1px #ffffff solid;
}
.address_input_google .title2 {
  background-color: #dddddd;
}
.address_input_google .icon-add {
  color: #404040;
  background-color: rgba(0, 255, 0, 0.5);
  border-radius: 50%;
}
.address_input_google .icon-remove {
  color: #404040;
  background-color: rgba(255, 0, 0, 0.5);
  border-radius: 50%;
}
.address_input_google .txtbody {
  border-right: 1px #dddddd solid;
}
.address_input_google .autocomplete2-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  overflow: auto;
  position: absolute;
  background-color: white;
  width: 100%;
  z-index: 100;
}

.address_input_google .autocomplete2-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.address_input_google .autocomplete2-result:hover {
  background-color: #4aae9b;
  color: white;
}

.address_input_google .full_screen_cover {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 10;
  opacity: 0.5;
  background: #29343a;
  color: white;
}

.address_input_google .new_address {
  padding: 2%;
  position: fixed;
  top: calc(50% - 200px);
  left: 15%;
  width: 70%;
  z-index: 10;
  background: white;
}
</style>